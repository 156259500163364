exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-allied-health-tsx": () => import("./../../../src/pages/allied-health.tsx" /* webpackChunkName: "component---src-pages-allied-health-tsx" */),
  "component---src-pages-become-a-partner-tsx": () => import("./../../../src/pages/become-a-partner.tsx" /* webpackChunkName: "component---src-pages-become-a-partner-tsx" */),
  "component---src-pages-blog-category-sub-tsx": () => import("./../../../src/pages/blog-category-sub.tsx" /* webpackChunkName: "component---src-pages-blog-category-sub-tsx" */),
  "component---src-pages-blog-category-tsx": () => import("./../../../src/pages/blog-category.tsx" /* webpackChunkName: "component---src-pages-blog-category-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-classes-active-yoga-tsx": () => import("./../../../src/pages/classes-active-yoga.tsx" /* webpackChunkName: "component---src-pages-classes-active-yoga-tsx" */),
  "component---src-pages-classes-boxing-tsx": () => import("./../../../src/pages/classes-boxing.tsx" /* webpackChunkName: "component---src-pages-classes-boxing-tsx" */),
  "component---src-pages-classes-cross-training-tsx": () => import("./../../../src/pages/classes-cross-training.tsx" /* webpackChunkName: "component---src-pages-classes-cross-training-tsx" */),
  "component---src-pages-classes-meditation-tsx": () => import("./../../../src/pages/classes-meditation.tsx" /* webpackChunkName: "component---src-pages-classes-meditation-tsx" */),
  "component---src-pages-classes-mind-body-tsx": () => import("./../../../src/pages/classes-mind-body.tsx" /* webpackChunkName: "component---src-pages-classes-mind-body-tsx" */),
  "component---src-pages-classes-pilates-tsx": () => import("./../../../src/pages/classes-pilates.tsx" /* webpackChunkName: "component---src-pages-classes-pilates-tsx" */),
  "component---src-pages-classes-tai-chi-tsx": () => import("./../../../src/pages/classes-tai-chi.tsx" /* webpackChunkName: "component---src-pages-classes-tai-chi-tsx" */),
  "component---src-pages-commercial-tsx": () => import("./../../../src/pages/commercial.tsx" /* webpackChunkName: "component---src-pages-commercial-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-enquiries-tsx": () => import("./../../../src/pages/enquiries.tsx" /* webpackChunkName: "component---src-pages-enquiries-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-fitness-workout-at-home-tsx": () => import("./../../../src/pages/fitness-workout-at-home.tsx" /* webpackChunkName: "component---src-pages-fitness-workout-at-home-tsx" */),
  "component---src-pages-fitness-workout-live-stream-tsx": () => import("./../../../src/pages/fitness-workout-live-stream.tsx" /* webpackChunkName: "component---src-pages-fitness-workout-live-stream-tsx" */),
  "component---src-pages-fitness-workout-outdoors-tsx": () => import("./../../../src/pages/fitness-workout-outdoors.tsx" /* webpackChunkName: "component---src-pages-fitness-workout-outdoors-tsx" */),
  "component---src-pages-generic-tsx": () => import("./../../../src/pages/generic.tsx" /* webpackChunkName: "component---src-pages-generic-tsx" */),
  "component---src-pages-help-desk-tsx": () => import("./../../../src/pages/help-desk.tsx" /* webpackChunkName: "component---src-pages-help-desk-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-stream-time-tsx": () => import("./../../../src/pages/live-stream-time.tsx" /* webpackChunkName: "component---src-pages-live-stream-time-tsx" */),
  "component---src-pages-local-councils-tsx": () => import("./../../../src/pages/local-councils.tsx" /* webpackChunkName: "component---src-pages-local-councils-tsx" */),
  "component---src-pages-location-detail-tsx": () => import("./../../../src/pages/location-detail.tsx" /* webpackChunkName: "component---src-pages-location-detail-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-mind-mood-live-stream-tsx": () => import("./../../../src/pages/mind-mood-live-stream.tsx" /* webpackChunkName: "component---src-pages-mind-mood-live-stream-tsx" */),
  "component---src-pages-nutrition-healthy-guide-tsx": () => import("./../../../src/pages/nutrition-healthy-guide.tsx" /* webpackChunkName: "component---src-pages-nutrition-healthy-guide-tsx" */),
  "component---src-pages-partnerships-tsx": () => import("./../../../src/pages/partnerships.tsx" /* webpackChunkName: "component---src-pages-partnerships-tsx" */),
  "component---src-pages-post-detail-tsx": () => import("./../../../src/pages/post-detail.tsx" /* webpackChunkName: "component---src-pages-post-detail-tsx" */),
  "component---src-pages-primary-health-networks-tsx": () => import("./../../../src/pages/primary-health-networks.tsx" /* webpackChunkName: "component---src-pages-primary-health-networks-tsx" */),
  "component---src-pages-search-location-result-tsx": () => import("./../../../src/pages/search-location-result.tsx" /* webpackChunkName: "component---src-pages-search-location-result-tsx" */),
  "component---src-pages-social-prescribing-tsx": () => import("./../../../src/pages/social-prescribing.tsx" /* webpackChunkName: "component---src-pages-social-prescribing-tsx" */),
  "component---src-pages-video-detail-lock-tsx": () => import("./../../../src/pages/video-detail-lock.tsx" /* webpackChunkName: "component---src-pages-video-detail-lock-tsx" */),
  "component---src-pages-video-detail-tsx": () => import("./../../../src/pages/video-detail.tsx" /* webpackChunkName: "component---src-pages-video-detail-tsx" */),
  "component---src-pages-video-hub-tsx": () => import("./../../../src/pages/video-hub.tsx" /* webpackChunkName: "component---src-pages-video-hub-tsx" */),
  "component---src-pages-video-main-category-tsx": () => import("./../../../src/pages/video-main-category.tsx" /* webpackChunkName: "component---src-pages-video-main-category-tsx" */),
  "component---src-pages-video-sub-category-tsx": () => import("./../../../src/pages/video-sub-category.tsx" /* webpackChunkName: "component---src-pages-video-sub-category-tsx" */)
}

